export const preventNavigation = (e: any) => {
  e.preventDefault();
  e.returnValue = '';
};

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getTarget() {
  return inIframe() ? window.parent : document;
}

import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

interface ITitleProps extends HTMLAttributes<HTMLHeadingElement>{
  className?: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  spacing?: 'large' | 'medium' | 'small';
  size?: 'extra-large' | 'large' | 'medium' | 'small' | 'extra-small';
}

export default class Title extends React.Component<ITitleProps> {
  public static defaultProps = {
    element: 'h1',
  };

  public render() {
    const {
      element,
      children,
      className,
      spacing,
      size,
      ...props
    } = this.props;

    const newClassName = classNames(
      'z-title',
      Boolean(spacing) && `z-title--${spacing}-space`,
      Boolean(size) && `z-title--${size}`,
      className,
    );

    const newProps = {
      ...props,
      className: newClassName,
    };

    return React.createElement(element!, newProps, children);
  }
}

import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import './card.scss';

interface ICardProps extends HTMLAttributes<HTMLDivElement> {
  overrideStyle?: boolean; // Removes default zilla styling
}

export default class Card extends React.Component<ICardProps> {
  public static defaultProps = {
    overrideStyle: false,
  };

  public render() {
    const newClassNames = classNames(
      !this.props.overrideStyle && 'z-card',
      this.props.className,
    );

    return (
      <div className={newClassNames}>
        {this.props.children}
      </div>
    );
  }
}

import React from 'react';
import _ from 'lodash';
import { qbApiService } from '@services/origins/QBApiService';
import Section from '@components/section';

export const makeResize = (message = 'Iframe available') => () => {
  const serializable = {
    height: document.body.offsetHeight,
    message,
  };

  window.parent.postMessage(JSON.stringify(serializable), qbApiService.host);
};

export const withPostMessage = (Comp: any, message?: string) => {
  const configuredEventListener = _.throttle(makeResize(message), 100);

  return class extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      window.addEventListener('resize', configuredEventListener);
    }

    public componentDidMount() {
      configuredEventListener();
    }

    public componentWillUnmount() {
      window.removeEventListener('resize', configuredEventListener);
    }

    public render() {
      const { className, ...props } = this.props;

      return (
        <Section.Basic className={className}>
          <Comp {...props} />
        </Section.Basic>
      );
    }
  };
};

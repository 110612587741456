import { IMockItem } from '@interfaces/types';
import { makeMock } from '@utils/services';

interface IUserMock {
  getMe: IMockItem;
}

const existingUser = {
  cpf: '409.301.348-93',
  csrf_token: 'qWLFmXPi3dyDDQB7nSHFOTVeS7qvaAJnoAEVW5PdNrdYDIzCkCe/XnbYUjE8' +
    'aU5cPc5NhKjk26QdGdzKXPjikg==',
  email: 'somedude@someprovider.somedomain',
  externalProvider: 'someexternalprovider',
  externalUId: 123456789,
  high_school_type: 2,
  how_you_found: null,
  id: null,
  income_range: 2,
  is_pre_user: false,
  name: 'A Dude With A Name',
  phone: '11999999999',
  profile_image: null,
  qbt: 'eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImxvZ2luX2lkIjo0MjM0MzEwLCJwcmVfdX' +
    'Nlcl9pZCI6MzY3ODk2NzY1fSwiaXNzIjoiaHR0cHM6Ly9xdWVyb2JvbHNhLmNvbSIsImV4' +
    'cCI6MTU1OTMzMDEzMH0.W9wKsNMG1I9S1ZE-ZSx98sAwzpSlwPB2jI3JLlGfry8',
};

const newUser = {
  cpf: null,
  csrf_token: 'qWLFmXPi3dyDDQB7nSHFOTVeS7qvaAJnoAEVW5PdNrdYDIzCkCe/XnbYUjE8' +
    'aU5cPc5NhKjk26QdGdzKXPjikg==',
  email: 'somedude@someprovider.somedomain',
  externalProvider: 'qb',
  externalUId:	123456789,
  high_school_type: null,
  how_you_found: null,
  id: null,
  income_range:  null,
  is_pre_user: false,
  name: null,
  phone: null,
  profile_image: null,
  qbt: 'eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImxvZ2luX2lkIjo0MjM0MzEwLCJwcmVfdX' +
    'Nlcl9pZCI6MzY3ODk2NzY1fSwiaXNzIjoiaHR0cHM6Ly9xdWVyb2JvbHNhLmNvbSIsImV4' +
    'cCI6MTU1OTMzMDEzMH0.W9wKsNMG1I9S1ZE-ZSx98sAwzpSlwPB2jI3JLlGfry8',
  wishes: {
    cities: [],
    courses: [],
    universities: [],
  },
};

export const users = {
  existingUser,
  newUser,
};

export const UserMock = makeMock<IUserMock>({
  getMe: {
    fail: new Error('UserService.getMe'),
    success: users.newUser,
  },
});

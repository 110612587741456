import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Store } from 'redux';

import { AppWithRoutes } from './AppWithRoutes';
import ErrorGateway from './ErrorGateway';
import store from './Store';

export class Providers extends React.PureComponent<{ store?: Store }> {
  public render() {
    return (
      <Provider store={this.props.store || store}>
        <Router>
          <ErrorGateway>
            {this.props.children}
          </ErrorGateway>
        </Router>
      </Provider>
    );
  }
}

export default class App extends React.PureComponent {
  public render() {
    return (
      <Providers>
        <AppWithRoutes/>
      </Providers>
    );
  }
}

import './iframe.scss';

import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { withPostMessage } from '@hocs/withPostMessage';

export const IFrameLoading = withPostMessage((props: HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={classNames(
      'iframe__loading',
      props.className,
    )}
  />
));

const IFrame = (props : HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={classNames('iframe', props.className)} />
);

export default IFrame;

import _ from 'lodash';

import humps from './humps';

import { IMock } from '@interfaces/types';

export function mockApi(mockReturn: object | boolean | Error = {}, timeout?: number) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (process.env.REACT_APP_MOCK === 'true'
      || process.env.STORYBOOK_MOCK === 'true'
      || process.env.NODE_ENV === 'test'
    ) {
      descriptor.value = (...args: any[]) => new Promise((res, rej) => {
        // Disables lint because it only triggers when mocking
        // tslint:disable-next-line:no-console
        console.log({ target, propertyKey, descriptor, args });

        const method = mockReturn instanceof Error
          ? rej
          : (a: any) => res(humps(a));

        const mockTime = process.env.NODE_ENV === 'test'
          || process.env.INSTANT_RESPONSE === 'true'
          ? 0 : timeout || _.random(1000, 4000, false);

        setTimeout(method, mockTime, mockReturn);
      });
    }

    return descriptor;
  };
}

export function cacheOnSuccess(fnName: string, targetCacheObject: { [x: string]: any }) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value.bind(target);

    descriptor.value = async (...args: any[]) => {
      if (targetCacheObject[fnName] !== undefined) return targetCacheObject[fnName];

      return targetCacheObject[fnName] = await original(...args);
    };

    return descriptor;
  };
}

// This exists just to ensure typing correctness
export function makeMock<T>(obj: IMock & T) {
  return obj;
}

export interface FileFieldState {
  error: Nullable<string>;
  loading: boolean;
}

export interface FieldConfig {
  key: DocType;
  title: string;
  accept?: string;
  helperText?: string;
}

export interface TrackableFile {
  uploaded: boolean;
  file: Nullable<File>;
}

export interface FileFieldProps extends Partial<HTMLInputElement> {
  loading: boolean;
  title: string;
  fileUrl: Doc;
  onInput: (file: File) => void;
  onDelete: (file: File) => void;
  trackable: TrackableFile;
  error: Nullable<string>;
  name: string;
  helperText?: string;
}

export interface Doc {
  doc_id: number;
  post: Nullable<{ url: string, fields: { [x: string]: string } }>;
}

export interface FilesUrls {
  selfie?: Doc;
  matricula?: Doc;
  residencia?: Doc;
}

export interface Card {
  birthday: string;
  course: string;
  courseLevel: string;
  cpf: string;
  id: number;
  ies: string;
  iesLogoPath: string;
  profilePicPath: string;
  qbLogoPath: string;
  status: string;
  title: string;
  useCode: string;
  userFullName: string;
}

export interface CarteirinhaData {
  filesUrls: Nullable<FilesUrls>;
  card: Nullable<Card>;
  isDigital: boolean;
  isUnavailable: boolean;
  showMobile: boolean;
}

export interface CarteirinhaState {
  data: CarteirinhaData;
  errors: Nullable<{ [x: string]: Nullable<string> }>;
  files: Nullable<{ [x: string]: TrackableFile }>;
  loading: boolean;
}

export interface ChildFormProps {
  data: any;
  makeHandleSubmit: (fields: FieldConfig[]) => () => void;
  loading: boolean;
  renderFileField: (__: any, i: number, arr: FieldConfig[]) => any;
}

export enum DocType {
  IDENTITY = 'identity',
  ADDRESS = 'address',
  ENROLLMENT = 'enrollment',
  SELFIE = 'selfie',
}

export const DocTypeMapper = {
  [DocType.IDENTITY]: {
    gender: 'o',
    value: 'comprovante de identidade',
  },
  [DocType.ADDRESS]: {
    gender: 'o',
    value: 'comprovante de endereço',
  },
  [DocType.ENROLLMENT]: {
    gender: 'o',
    value: 'comprovante de matrícula',
  },
  [DocType.SELFIE]: {
    gender: 'a',
    value: 'foto selfie',
  },
};

export enum DocStatus {
  DRAFT = 'draft',
  AWAITING_SUBMISSION = 'awaiting_submission',
  AWAITING_VALIDATION = 'awaiting_validation',
  VALIDATED = 'validated',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
}

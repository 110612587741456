import { IUser } from '@features/profile/UserRedux.interface';
import { UserMock } from '@mocks/user.mock';
import { caApiService } from '@services/origins/CAApiService';
import { qbApiService } from '@services/origins/QBApiService';
import { mockApi } from '@utils/services';
import Cookies from 'universal-cookie';
import { clearCookies } from '@utils/cookies';
import { getTarget } from '@utils/window';

function isFirstLogin() {
  const cookies = new Cookies();
  return cookies.get('first_login') === 'true';
}

function removeFirstLoginCookie() {
  const cookies = new Cookies();
  cookies.remove('first_login', { path: '/' });
}

function logRegisteredUser() {
  if (isFirstLogin()) {
    removeFirstLoginCookie();
  }
}

class UserService {
  @mockApi(UserMock.getMe.success)
  public getMe() {
    return caApiService.simpleGet(`${caApiService.host}/querobolsa/user/me`)
      .then(async (me: IUser) => {

        qbApiService.qbt = me.qbt || null;
        qbApiService.csrfToken = me.csrfToken || null;
        logRegisteredUser();

        return me;
      });
  }

  public login(route?: string) {
    clearCookies();

    const postBackURL = encodeURIComponent(route || getTarget().location.href);
    const host = qbApiService.host;

    return (getTarget().location.href = `${host}/login?url=${postBackURL}`);
  }

  @mockApi()
  public submitRegister(values: any = {}) {
    return caApiService.simplePost(
      `${caApiService.host}/querobolsa/register`,
      values,
      qbApiService.getAuthHeader(),
    );
  }

  public card(token: string) {
    return caApiService.simpleGet(
      `${caApiService.host}/user/card/web/${token}`,
    );
  }
}

export const userService = new UserService();

import classNames from 'classnames';
import React from 'react';

import './section.scss';

interface ISectionProps {
  className?: string;
  children?: any;
  mainClassName?: string;
}

const makeSection = (clsname: string) => ({ className, children }: ISectionProps) => (
  <div className={classNames(clsname, className)}>
    {children}
  </div>
);

export const Section = {
  Basic: ({ children, className, mainClassName }: ISectionProps) => (
    <Section.Root className={className}>
      <Section.Positioner>
        <Section.Main className={mainClassName}>
          <div>
            {children}
          </div>
        </Section.Main>
      </Section.Positioner>
    </Section.Root>
  ),
  Main: makeSection('ca-section__main'),
  Positioner: makeSection('ca-section__positioner'),
  Root: makeSection('ca-section'),
};

export type CAPages =
  'register'
  | 'courses'
  | 'card';

interface Route {
  name: CAPages;
  path: string;
}

export const CarteirinhaApplicationRoute: Route = {
  name: 'register',
  path: '/register',
};

export const CarteirinhaCoursesRoute: Route = {
  name: 'courses',
  path: '/courses',
};

export const CarteirinhaCartRoute: Route = {
  name: 'card',
  path: '/card/:token',
};

export const routes = [
  CarteirinhaApplicationRoute,
  CarteirinhaCoursesRoute,
  CarteirinhaCartRoute,
];

const routeNameByPathname: { [x: string]: CAPages } = routes.reduce((prev, curr) => ({
  ...prev,
  [curr.path]: curr.name,
}), {});

export const getPageName = () => routeNameByPathname[document.location.pathname];

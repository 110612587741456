import _ from 'lodash';
import Rollbar from 'rollbar';

import { IAppState } from '@app/Store';
import { IUser } from '@features/profile/UserRedux.interface';
import { debounced } from '@utils/async';
import { Store } from 'redux';

const getRollbarConfig = () => {
  // https://docs.rollbar.com/docs/rollbarjs-configuration-reference
  const config: Rollbar.Configuration = {};

  config.environment = process.env.NODE_ENV;
  config.enabled = config.environment !== 'development'
   && config.environment !== 'test';

  if (config.environment === 'development') {
    if (process.env.ROLLBAR_TOKEN) {
      // tslint:disable-next-line:no-console
      console.warn('ROLLBAR_TOKEN set in development.');
    }
  }

  if (!config.enabled) return config;

  config.autoInstrument = true;
  config.accessToken = process.env.ROLLBAR_TOKEN;
  config.captureEmail = true;
  config.captureUncaught = true;
  config.captureUnhandledRejections = true;
  config.captureUsername = true;
  config.codeVersion = `${process.env.APP_VERSION}-${process.env.GIT_SHA}`;
  config.itemsPerMinute = undefined; // No limit
  config.payload = {
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
    environment: config.environment,
    server: {
      host: 'https://github.com/redealumni/carteirinha',
    },
  };
  config.uncaughtErrorLevel = 'critical';

  if (config.environment === 'staging') {
    _.set(config, 'payload.server.branch',  'dev');
    config.sendConfig = true;
    config.verbose = true;
  }

  if (config.environment === 'production') {
    config.reportLevel = 'warning';
    config.scrubTelemetryInputs = true;
    // Concats with default fields
    config.scrubFields = [];
  }

  return config;
};

export class RollbarService extends Rollbar {
  private static instance: Nullable<RollbarService> = null;
  private static store: Nullable<Store> = null;

  public static initStoreReference(store: Store) {
    RollbarService.store = store;
  }

  public static getInstance() {
    if (!RollbarService.store && !RollbarService.instance) {
      if (process.env.NODE_ENV !== 'test') {
        throw new Error('Rollbar didn\'t initiate a redux store.');
      }
    }

    if (!RollbarService.instance) {
      return RollbarService.instance = new RollbarService();
    }

    return RollbarService.instance;
  }

  // https://github.com/rollbar/rollbar.js/blob/master/src/browser/rollbar.js#L26
  // https://github.com/rollbar/rollbar.js/blob/master/src/browser/globalSetup.js#L42
  private constructor(config: Rollbar.Configuration = getRollbarConfig()) {
    super(config);
  }

  public handleUnhandledRejection(...args: any[]) {
    const [error] = args.filter(v => typeof v === 'object' && !(v instanceof Promise));
    const [promise] = args.filter(v => v instanceof Promise);

    if (error) {
      return this.register({ error });
    }

    const message = 'O navegador não suporta promises';
    return this.register({ error: new Error(message), context: promise, message });
  }

  public handleUncaughtException(...args: any[]) {
    // About script error:
    // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#Notes

    // This has to do with how browsers implement this function
    // Different browsers have different signatures, so it's best to just scan the arguments

    const [error] = args.filter(v => typeof v === 'object');
    const [message] = args.filter(v => typeof v === 'string');

    if (error) {
      return this.register({ error, level: 'critical', message });
    }

    if (message) {
      return this.register({ error: new Error(message), level: 'critical', message });
    }

    const _message = 'O navegador não possui trativa correta de erro';
    return this.register({ error: new Error(_message), level: 'critical', message: _message });
  }

  public register = (
    { context, error, level = 'error', message }: RollbarService.ReactError,
  ): { uuid: string } => {
    return (this as any)[level](this.messageStamp(message || error.message), error, this.getContext(context));
  }

  public setUser = (user: IUser) => {
    this.configure({
      payload: {
        person: {
          email: user.email,
          id: user.externalUId,
          username: user.name,
        },
      },
    });
  }

  public unsetUser = () => {
    this.configure({
      payload: {
        person: {
          id: null,
        },
      },
    });
  }

  private getContext = (additionalContext: object) => {
    if (!RollbarService.store) return {};

    const state = RollbarService.store.getState() as IAppState;
    const user = state.user.me;

    if (!user) return {};

    return _.merge(user, additionalContext);
  }

  private messageStamp = (message: string) => {
    return `[CAW] ${message || 'No message'}`;
  }
}

export const debouncedWarn = debounced((...args: any[]) => {
  RollbarService.getInstance().warn(...args);
});

import axios from 'axios';

export class BaseService {
  private basicHeaders = {
    Accept: 'application/json',
    'x-application-id': process.env.CA_API_TOKEN,
  };

  public simplePost(
    uri: string,
    data: any = null,
    customHeaders = {},
    withCredentials: boolean = true,
  ) {
    const headers = {
      ...this.basicHeaders,
      ...customHeaders,
    };

    return axios
      .post(uri, data, { headers, withCredentials })
      .then(result => result.data);
  }

  public simpleDelete(
    uri: string,
    data: any = null,
    customHeaders = {},
    withCredentials: boolean = true,
  ) {
    const headers = {
      ...this.basicHeaders,
      ...customHeaders,
    };

    return axios
      .delete(uri, { headers, withCredentials })
      .then(result => result.data);
  }

  public simplePut(
    uri: string,
    data: any = null,
    customHeaders = {},
    withCredentials: boolean = true,
  ) {
    const headers = {
      ...this.basicHeaders,
      ...customHeaders,
    };

    return axios
      .put(uri, data, { headers, withCredentials })
      .then(result => result.data);
  }

  public simpleGet(
    uri: string,
    params: any = null,
    customHeaders = {},
    withCredentials: boolean = true,
  ) {
    const headers = {
      ...this.basicHeaders,
      ...customHeaders,
    };

    return axios
      .get(uri, { headers, params, withCredentials })
      .then(result => result.data);
  }
}

import { BaseService } from '@services/BaseService';
import { qbApiService } from './QBApiService';

import { CarteirinhaMock } from '@mocks/carteirinha.mock';
import { mockApi } from '@utils/services';

export class CAApiService extends BaseService {
  public host = `${process.env.CA_API_HOST}/api/v1`;

  @mockApi(CarteirinhaMock.getCarteirinhaForm.success)
  public getRegisterForm() {
    return this.simpleGet(`${this.host}/user/form`, null, qbApiService.getAuthHeader());
  }

  @mockApi(CarteirinhaMock.getHasProcess.success)
  public getHasProcess(): Promise<{ shouldRender: boolean }> {
    return this.simpleGet(`${this.host}/user/form/render`, null, qbApiService.getAuthHeader());
  }

  @mockApi(CarteirinhaMock.getCarteirinhaForm.success)
  public postRegisterForm({ uploaded }: { uploaded: number[] }) {
    return this.simplePost(`${this.host}/user/doc`, { uploaded }, qbApiService.getAuthHeader());
  }
}

export const caApiService = new CAApiService();

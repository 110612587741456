import React, { lazy, Suspense } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { CarteirinhaApplicationRoute, CarteirinhaCoursesRoute, CarteirinhaCartRoute } from './Routes';
import './app.scss';
import DefaultSpinner from '@components/spinner/DefaultSpinner';

const RegisterFrame = lazy(() => import('@iframes/account/RegisterFrame'));
const CoursesFrame = lazy(() => import('@iframes/my-courses-banner/MyCoursesBanner'));
const AuthRoute = lazy(() => import('@features/profile/AuthRoute'));
const CompleteCard = lazy(() => import('@features/card/CompleteCard'));

export const AppWithRoutes = withRouter(
  class extends React.Component<RouteComponentProps> {
    public render() {
      return (
        <Suspense fallback={<DefaultSpinner/>}>
          <Switch>
            <Route
              path={CarteirinhaApplicationRoute.path}
              exact={true}
              strict={true}
              sensitive={true}
            >
              <AuthRoute>
                <RegisterFrame/>
              </AuthRoute>
            </Route>

            <Route
              path={CarteirinhaCoursesRoute.path}
              exact={true}
              strict={true}
              sensitive={true}
            >
              <AuthRoute>
                <CoursesFrame/>
              </AuthRoute>
            </Route>
            <Route
              path={CarteirinhaCartRoute.path}
              exact={true}
              strict={true}
              sensitive={true}
            >
              <CompleteCard/>
            </Route>
          </Switch>
        </Suspense>
      );
    }
  });

import amplitude from 'amplitude-js';
import store from '@app/Store';
import { RollbarService } from '@services/analytics/RollbarService';

export default async (): Promise<void> => {
  if (process.env.NODE_ENV === 'test') return;

  RollbarService.initStoreReference(store);

  await Promise.all([
    Promise.resolve(amplitude.init(process.env.AMPLITUDE_KEY)),
  ]);
};

import { IMockItem } from '@interfaces/types';
import { makeMock } from '@utils/services';
import { DocStatus, DocType } from '@features/register/types';
import { EnrollmentOrigin } from '@features/profile/types';

interface CAMock {
  getCarteirinhaForm: IMockItem;
}

const successCard = {
  birthday: '2019',
  course: 'Matemática',
  courseLevel: 'Graduação',
  cpf: '409.301.348-83',
  id: 128,
  ies: 'UNIP',
  iesLogoPath: 'https://anyohterplace.com',
  profilePicPath: 'https://qqlugar.com',
  qbLogoPath: 'https://qqoutrolugar.com',
  status: 'not_awaiting', // 'AWAITING_APPROVAL',
  title: 'Carteirinha Digital Aluno Quero',
  useCode: '817623',
  userFullName: 'Luigi Perotti',
};

export const filesUrls = {
  [DocType.ENROLLMENT]: {
    doc_id: 123,
    post: {
      fields: {
        'x-application-header': 'etc',
      },
      url: 'http://rolezao',
    },
    status: DocStatus.DRAFT,
  },
  [DocType.ADDRESS]: {
    doc_id: 456,
    post: {
      fields: {
        'x-application-header': 'etc',
      },
      url: 'http://rolezao',
    },
    status: DocStatus.DRAFT,
  },
  [DocType.SELFIE]: {
    doc_id: 543,
    post: {
      fields: {
        'x-application-header': 'etc',
      },
      url: 'http://rolezao',
    },
    status: DocStatus.DRAFT,
  },
  [DocType.IDENTITY]: {
    doc_id: 543,
    post: {
      fields: {
        'x-application-header': 'etc',
      },
      url: 'http://rolezao',
    },
    status: DocStatus.DRAFT,
  },
};

export const successGetForm = {
  card: null,
  filesUrls: {
    address: {
      doc_id: '4147',
      post: {
        fields: {},
        url: 'https://s3.sa-east-1.amazonaws.com/carteirinha-docs',
      },
      status: 'rejected',
    },
  },
  showMobile: false,
  userOrigin: EnrollmentOrigin.INTEGRATED, // EnrollmentOrigin.DIGITAL
};

export const successWithExistingCard = { ...successGetForm, filesUrls, card: successCard };

const fail = (() => {
  const error = new Error() as any;
  error.response = { status: 404 };

  return error;
})();

export const CarteirinhaMock = makeMock<CAMock>({
  getCarteirinhaForm: {
    fail,
    success: successGetForm,
  },
  getHasProcess: {
    fail,
    success: {
      shouldRender: true,
    },
  },
});

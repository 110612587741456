import amplitude, { AmplitudeClient } from 'amplitude-js';
import _ from 'lodash';
import { IUser } from '@features/profile/UserRedux.interface';
import {
  ClickedOnAccountsAndPreferencesCarteirinhaDownloadApp,
  ClickedOnAccountsAndPreferencesCarteirinhaSubmitForm,
  ClickedOnMyCoursesBannerButton,
  ViewedOnAccountsAndPreferencesCarteirinhaDocumentsApproved,
  ViewedOnAccountsAndPreferencesCarteirinhaDocumentsRejected,
  ViewedOnAccountsAndPreferencesCarteirinhaForm,
  ViewedOnAccountsAndPreferencesCarteirinhaValidating,
  ViewedOnMyCoursesBanner,
} from './AmplitudeEvents';

interface IAmplitudeOptions {
  defaultProperties?: object;
  debounceInterval?: number;
  namespace?: string;
}

type DocFormType = 'digital' | 'integrated';

class AmplitudeServer {
  private amplitude?: AmplitudeClient;
  private defaultProperties?: object;
  private logEvent: any;

  public setUser(aUser: IUser) {
    if (this.amplitude) {
      this.amplitude.setUserId(aUser.externalUId.toString());
      this.amplitude.setUserProperties({
        externalProvider: aUser.externalProvider,
      });
    }
  }

  constructor(options: IAmplitudeOptions = {}) {
    if (process.env.NODE_ENV !== 'test') {
      this.amplitude = amplitude.getInstance(options.namespace);
      this.defaultProperties = options.defaultProperties;
    }

    if (typeof options.debounceInterval === 'number') {
      this.logEvent = _.debounce(this.makeLogEvent(), options.debounceInterval);
    } else {
      this.logEvent = this.makeLogEvent();
    }
  }

  private makeLogEvent = () => (event: string, data: any, callback: () => void) => {
    if (this.amplitude) {
      return this.amplitude.logEvent(
        event,
        {
          ...(this.defaultProperties || {}),
          ...data,
        },
        callback,
      );
    }
  }

  public sendEventAmplitude(event: string, properties = {}) {
    return this.logEvent(event, properties);
  }
}

class AmplitudeService extends AmplitudeServer {
  constructor(options?: IAmplitudeOptions) {
    super(options);
  }

  // My Courses
  public viewedOnMyCoursesBanner = (active: boolean) => (
    this.sendEventAmplitude(ViewedOnMyCoursesBanner, { button_active: active }))

  public clickedOnMyCoursesBannerButton = () => (
    this.sendEventAmplitude(ClickedOnMyCoursesBannerButton))

  // Accounts and preferences
  public viewedOnAccountsAndPreferencesCarteirinhaForm = (type: DocFormType) => (
    this.sendEventAmplitude(ViewedOnAccountsAndPreferencesCarteirinhaForm, { type }))

  public viewedOnAccountsAndPreferencesCarteirinhaDocumentsRejected = (type: DocFormType) => (
    this.sendEventAmplitude(ViewedOnAccountsAndPreferencesCarteirinhaDocumentsRejected, { type }))

  public viewedOnAccountsAndPreferencesCarteirinhaValidating = () => (
    this.sendEventAmplitude(ViewedOnAccountsAndPreferencesCarteirinhaValidating))

  public viewedOnAccountsAndPreferencesCarteirinhaDocumentsApproved = () => (
    this.sendEventAmplitude(ViewedOnAccountsAndPreferencesCarteirinhaDocumentsApproved))

  public clickedOnAccountsAndPreferencesCarteirinhaSubmitForm = (type: DocFormType) => (
    this.sendEventAmplitude(ClickedOnAccountsAndPreferencesCarteirinhaSubmitForm, { type }))

  public clickedOnAccountsAndPreferencesCarteirinhaDownloadApp = (
    os: 'ios' | 'android',
    carteirinha_status: 'validating' | 'approved',
  ) => this.sendEventAmplitude(ClickedOnAccountsAndPreferencesCarteirinhaDownloadApp,  { os, carteirinha_status })
}

export const getInstance = (options?: IAmplitudeOptions) => {
  return new AmplitudeService(options);
};

export const amplitudeService = getInstance();

import classNames from 'classnames';
import React from 'react';

import Card from '@components/card';

import './spinner.scss';

interface ISpinnerProps {
  text?: string;
  className?: string;
  overrideStyle?: boolean;
  size?: 'extra-small' | 'small' | 'large' | 'extra-large';
}

const Subtitle = React.memo(({ text }: ISpinnerProps) => (
  !text ? null : (
    <p className="z-text z-text--bold spinner__text">
      {text}
    </p>
  )));

const SpinnerIcon = React.memo(({ size }: ISpinnerProps) => {
  return <div className={classNames('spinner', size && `spinner--${size}`)} />;
});

export default class Spinner extends React.Component<ISpinnerProps> {
  public static defaultProps: Partial<ISpinnerProps> = {
    size: 'extra-large',
  };

  public render() {
    const className = classNames(this.props.className, 'spinner__wrapper');

    return this.props.overrideStyle
      ? (
        <SpinnerIcon size={this.props.size}/>
      ) : (
        <div className={className}>
          <Card className="spinner__card">
            <SpinnerIcon size={this.props.size}/>
            <Subtitle text={this.props.text}/>
          </Card>
        </div>
      );
  }
}

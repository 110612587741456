/// <reference types="./interfaces/react-app-env" />
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from '@app/App';
import appPreLoader from '@app/PreLoader';

import '@assets/stylesheets/index.scss';

const caRoot = document.getElementById('carteirinha-root');
const noSupportTag = document.getElementById('no-support')!;
const noSupportHTML = `
<p>
  Seu navegador não suporta a Carteirinha,
  utilize um dos navegadores <a target="_blank" href="https://browserl.ist/?q=%3E+0.2%25">desta lista</a>.
</p>
`;

(function start() {
  if (noSupportTag) {
    noSupportTag.innerHTML = noSupportHTML;
    return;
  }

  appPreLoader().then(() => ReactDOM.render(<App/>, caRoot));
})();

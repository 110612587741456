import { BaseService } from '@services/BaseService';

class QBApiService extends BaseService {
  public qbt: Nullable<string> = null;
  public csrfToken: Nullable<string> = null;
  public host = process.env.QB_API_HOST as string;

  public getAuthHeader() {
    return { Authorization: `Bearer ${this.qbt}` };
  }
}

export const qbApiService = new QBApiService();

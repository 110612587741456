import React from 'react';

export default class ErrorGateway extends React.Component {
  // We don't have a fallback ErrorScreen for now
  // So it's no use implementing didCatch and derivedStateFromError

  public render() {
    return this.props.children;
  }
}

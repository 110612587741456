import {
  camelCase,
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  set,
  transform,
} from 'lodash';

function createIteratee(converter: any, self: any) {
  return (result: any, value: any, key: any) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter: any) {
  return function humps(node: any): any {
    if (isArray(node)) return map(node, humps);
    if (isPlainObject(node)) return transform(node, createIteratee(keyConverter, humps));
    return node;
  };
}

export default createHumps(camelCase);

import { IFrameLoading } from '@components/iframe/IFrame';
import Spinner from '@components/spinner/Spinner';
import React from 'react';

export default () => {
  return (
    <IFrameLoading>
      <Spinner overrideStyle={true} size="small" />
    </IFrameLoading>
  );
};

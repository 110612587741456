import { amplitudeService } from '@services/analytics/AmplitudeService';
import { RollbarService } from '@services/analytics/RollbarService';
import { userService } from '@services/resources/UserService';

import IUserState, { IUser } from '@features/profile/UserRedux.interface';
import { NETWORK_ERROR, ThunkAction } from '@interfaces/types';

const USER_SET_ME = 'USER_SET_ME';
const USER_UNREACHABLE = 'USER_UNREACHABLE';

export const setMe = (me: IUser) => ({ type: USER_SET_ME, me });
const setUnreachable = () => ({ type: USER_UNREACHABLE });

export function refreshUser(): ThunkAction {
  return (dispatch, getState) => {
    function setServices({ me, meChanged }: any) {
      if (!meChanged) {
        return;
      }

      try {
        RollbarService.getInstance().setUser(me);
        amplitudeService.setUser(me);
      } catch (err) {
        // This is temporary.
        // It exists for sanity checking
        RollbarService.getInstance().error(err, 'Error from setServices in refreshUser');
      }
    }

    function setUser(me: IUser) {
      const oldMe = getState().user.me;

      const meChanged = oldMe && oldMe.externalUId !== me.externalUId;
      setServices({ me, meChanged: Boolean(meChanged) });

      dispatch(setMe(me));
    }

    function dealWithError(err: Error) {
      RollbarService.getInstance().unsetUser();

      if (NETWORK_ERROR.test(err.message)) {
        dispatch(setUnreachable());

        return;
      }

      userService.login();
    }

    return userService.getMe()
      .then(setUser)
      .catch(dealWithError);
  };
}

const initialState: IUserState = {
  me: null,
  setOnce: false,
};

type UserAction = ReturnType<typeof setMe>
  & ReturnType<typeof setUnreachable>;

export default function userReducer(state = initialState, action: UserAction) {
  switch (action.type) {
    case USER_SET_ME:
      return { setOnce: true, me: action.me };

    case USER_UNREACHABLE:
      return { setOnce: true, me: null };

    default:
      return state;
  }
}

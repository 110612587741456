import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import userReducer from '@features/profile/UserRedux';

const composeEnhancers = (() => {
  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  return compose;
})();

export const enhancers = composeEnhancers(applyMiddleware(thunk));

export const rootReducer = combineReducers({
  user: userReducer,
});

const store = createStore(rootReducer, enhancers);

export type IAppState = ReturnType<typeof store.getState>;

export default store;
